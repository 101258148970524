<template>
  <div>
    <label v-if="label" class="select-label">{{ label }}</label>
    <div class="select-container">
      <select
        :class="{ 'form-control': true }"
        :disabled="disabled"
        @blur="closeOptions"
        @focus="openOptions"
      />
      <div v-if="selected" class="select-value">
        <div v-if="!variant || variant === 'procedure'">
          <div class="procedure-prefix">
            <span class="icon-box">
              <v-procedure-icon v-if="selected.type === 'PROCEDURE'" class="icon stroke procedure-icon" />
              <v-telemedicine-icon v-if="selected.type === 'TELEMEDICINE'" class="icon stroke procedure-icon" />
              <v-appointment-icon v-if="selected.type === 'APPOINTMENT'" class="icon stroke procedure-icon" />
              <v-return-icon v-if="selected.type === 'RETURN'" class="icon stroke procedure-icon" />
              <v-exam-icon v-if="selected.type === 'EXAM'" class="icon stroke procedure-icon" />
              <v-surgical-icon v-if="selected.type === 'SURGICAL'" class="icon stroke procedure-icon" />
            </span>
            <span>{{ parseProcedureType(selected.type) }}</span>
            <div class="procedure-pipe" />
          </div>
          <span>{{ selected.name }}</span>
        </div>
        <div v-else>
          <div class="appointment-selected">
            <div v-if="selected">
              {{ `Atendimento realizado em ${moment(selected.start_datetime).format('DD/MM/YYYY [às] HH:mm')} - ${this.parsePaymentMethod(selected)}` }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="!selected" class="placeholder">{{ placeholder }}</div>

      <span class="icon-box"><v-chevron-down-select :class="{ 'icon': true, 'stroke': true, 'select-arrow': true, 'opened-options': showOptions }" /></span>
      <div v-if="showOptions" class="select-option-container">

        <div v-if="!appointments.length" class="empty-message">
          {{ emptyMessage }}
        </div>

        <div
          :class="{ option: true, pointer: variant === 'appointment' }"
          v-for="option in appointments"
          :key="option.label"
          @click="ev => variant === 'appointment' && handleSelected(ev, option.appointment)"
        >
          <div class="option-header">
            {{ option.header }}
          </div>
          <div
            :class="{ 'procedure-option': true, pointer: !variant || variant === 'procedure' }"
            v-for="procedure in option.appointment.appointment_procedures"
            :key="procedure.id"
            @click="ev => (!variant || variant === 'procedure') && handleSelected(ev, procedure)"
          >
            <div class="procedure-prefix">
              <span class="icon-box">
                <v-procedure-icon v-if="procedure.clinic_procedure.type === 'PROCEDURE'" class="icon stroke procedure-icon" />
                <v-telemedicine-icon v-if="procedure.clinic_procedure.type === 'TELEMEDICINE'" class="icon stroke procedure-icon" />
                <v-appointment-icon v-if="procedure.clinic_procedure.type === 'APPOINTMENT'" class="icon stroke procedure-icon" />
                <v-return-icon v-if="procedure.clinic_procedure.type === 'RETURN'" class="icon stroke procedure-icon" />
                <v-exam-icon v-if="procedure.clinic_procedure.type === 'EXAM'" class="icon stroke procedure-icon" />
                <v-surgical-icon v-if="procedure.clinic_procedure.type === 'SURGICAL'" class="icon stroke procedure-icon" />
              </span>
              <span>{{ parseProcedureType(procedure.clinic_procedure.type) }}</span>
              <div class="procedure-pipe" />
            </div>
            <span>{{ procedure.clinic_procedure.name }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import ChevronDown from '@/assets/icons/chevron-down-select.svg'
import Procedure from '@/assets/icons/procedure.svg'
import Telemedicine from '@/assets/icons/telemedicine.svg'
import Appointment from '@/assets/icons/appointment.svg'
import Return from '@/assets/icons/return.svg'
import Exam from '@/assets/icons/exam.svg'
import Surgical from '@/assets/icons/surgical.svg'

export default {
  name: 'Select',
  components: {
    'v-chevron-down-select': ChevronDown,
    'v-procedure-icon': Procedure,
    'v-telemedicine-icon': Telemedicine,
    'v-appointment-icon': Appointment,
    'v-return-icon': Return,
    'v-exam-icon': Exam,
    'v-surgical-icon': Surgical
  },
  props: {
    label: String,
    placeholder: String,
    options: { type: Array, required: true },
    value: { validator: v => true },
    variant: String,
    emptyMessage: String,
    disabled: Boolean
  },
  data() {
    return {
      showOptions: false,
      selected: null,
      appointment: null,
      appointments: [],
    }
  },
  created() {
    if (!this.value) return;
    if (!this.variant || this.variant === 'procedure') {
      this.selected = this.appointments.reduce((acc, option) => {
        const procedure = option.procedures && option.procedures.find(procedure => procedure.id === this.value.id);
        if (procedure) {
          acc = procedure;
        }
        return acc;
      }, null);
    } else {
      const option = this.appointments.find(option => option.appointment.id === this.value.id);
      if (option) {
        this.selected = option.appointment;
      }
    }
  },
  watch: {
    options: function(options) {
      this.appointments = options.map(appointment => ({
        appointment: appointment,
        header: `Atendimento realizado em ${moment(appointment.start_datetime).format('DD/MM/YYYY [às] HH:mm')} - ${this.parsePaymentMethod(appointment)}`,
        procedures: appointment.procedures,
      }));
    },
    value: function(newValue) {
      if (!this.variant || this.variant === 'procedure') {
        this.selected = this.appointments.reduce((acc, option) => {
          const procedure = option.procedures && option.procedures.find(procedure => procedure.id === newValue.id);
          if (procedure) {
            acc = procedure;
          }
          return acc;
        }, null);
      } else {
        const option = this.appointments.find(option => option.appointment.id === newValue.id);
        if (option) {
          this.selected = option.appointment;
        }
      }
    }
  },
  methods: {
    moment,
    parsePaymentMethod(appointment) {
      if (!appointment.health_plan) {
        return 'Convênio não definido';
      }

      switch(appointment.health_plan.type) {
        case 'particular':
          return 'Particular';
        case 'public_health':
          return 'SUS';
        case 'private_health':
          return 'Convênio';
        default:
          return appointment.health_plan.type;
      }
    },
    parseProcedureType(type) {
      switch(type) {
        case 'PROCEDURE':
          return 'Procedimento';
        case 'TELEMEDICINE':
          return 'Telemedicina';
        case 'APPOINTMENT':
          return 'Presencial';
        case 'RETURN':
          return 'Retorno';
        case 'EXAM':
          return 'Exame';
        case 'SURGICAL':
          return 'Centro Cirúrgico';
        default:
          return type;
      }
    },
    closeOptions() {
      setTimeout(() => {
        this.showOptions = false;
      }, 300);
    },
    openOptions() {
      this.showOptions = true;
    },
    handleSelected(ev, selected) {
      ev.stopPropagation();
      this.selected = Object.assign({}, selected);
      this.$emit('input', selected);

    }
  }
}
</script>
<style lang="scss" scoped>
  .select-label {
    width: 100%;
    font-family: 'Nunito Sans';
    font-weight: 700;
    font-size: 16px;
    color: #525C7A;
    text-align: left !important;
    color: var(--dark-blue);
    margin-bottom: 4px;
  }
  .select-container {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: left;

    .icon {
      transition: all 500ms;
    }

    .select-arrow {
      width: 25px;
      position: absolute;
      right: 10px;
      top: 15px;
      pointer-events: none;
    }
    .opened-options {
      transform: rotate(-180deg);
    }
    select {
      -webkit-appearance: none;
      appearance: none;
      border: 1px solid #C6CEEB;
      border-radius: 8px;
      padding: 20px;
      font-size: 16px;
      max-height: 56px;

      line-height: 1;
      outline: 0;
      background-color: var(--baseBg);
      background-image: linear-gradient(var(--baseFg), var(--baseFg)),
        linear-gradient(-135deg, transparent 50%, var(--accentBg) 50%),
        linear-gradient(-225deg, transparent 50%, var(--accentBg) 50%),
        linear-gradient(var(--accentBg) 42%, var(--accentFg) 42%);
      background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
      background-size: 1px 100%, 20px 22px, 20px 22px, 20px 100%;
      background-position: right 20px center, right bottom, right bottom, right bottom;
    }
    .placeholder {
      position: absolute;
      width: 89%;
      color: #8696AC;
      font-size: 16px;
      text-align: left;
      display: inline-flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 40px 0 20px;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .select-value {
      position: absolute;
      color: #525C7A;
      font-size: 16px;
      text-align: left;
      display: inline-flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0 40px 0 20px;
      pointer-events: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .select-option-container {
      position: absolute;
      max-height: 400px;
      background-color: white !important;
      width: 100%;
      top: 110%;
      left: 0;
      border-radius: 8px;
      border: 1px solid #C6CEEB;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
      overflow-x: auto;
      z-index: 100;
    }
    .option {
      font-size: 16px;
      color: var(--type-active);
      border-bottom: 1px solid #D9DFF2;
      font-family: 'Nunito Sans';
      cursor: pointer;
    }
    .option-header {
      padding: 20px 20px 10px 20px;
    }
    .procedure-option {
      padding: 10px 20px;
    }
    .placeholder-color {
      color: #8696AC;
    }
  }
  .procedure-prefix {
    display: inline-flex;
    align-items: center;
    color: var(--blue-500);
  }
  .appointment-selected {
    display: inline-flex;
    align-items: center;
    color: var(--type-active);
  }
  .procedure-pipe {
    height: 15px;
    width: 2px;
    background-color: var(--blue-500);
    margin: 0 5px;
    border-radius: 50%;
  }
  .procedure-icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    fill: var(--blue-500);
  }
  .empty-message {
    width: 100%;
    text-align: center;
    padding: 20px;
    color: var(--type-placeholder);
  }
  .pointer {
    cursor: pointer;
  }
  .pointer:hover {
    background-color: #F4F5FB;
  }

</style>
